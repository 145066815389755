import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react'

import { type SanityCollectionTreeItem } from '@data/sanity/queries/types/modules'
import { useMetadataOverride } from './metadata'
import { ProductCollectionContext } from './product-collection-context'

export interface QuickFilterOption {
  value: string
  title: string
  featuredProductIds?: number[]
}

interface ProductCollectionQuickFilterContextProps {
  quickFilterEnabled: boolean
  quickFilterOptions: QuickFilterOption[]
  quickFilterSelectedValue: string | null
  setQuickFilterValue: (newValue: string | null) => void
}

interface ProductCollectionQuickFilterContextProviderProps {
  collections: SanityCollectionTreeItem[]
  enabled: boolean
  quickFilterOptions: QuickFilterOption[]
  children: ReactNode
}

export const ProductCollectionQuickFilterContext =
  createContext<ProductCollectionQuickFilterContextProps>({
    quickFilterEnabled: false,
    quickFilterOptions: [],
    quickFilterSelectedValue: null,
    setQuickFilterValue: () => {},
  })

export const ProductCollectionQuickFilterContextProvider = ({
  collections,
  enabled,
  quickFilterOptions,
  children,
}: ProductCollectionQuickFilterContextProviderProps) => {
  const { activeCollectionUrlParameters, updateCollectionUrlParameters } =
    useContext(ProductCollectionContext)

  const quickFilterSelectedValue = useMemo(() => {
    const urlParameter = activeCollectionUrlParameters.find(
      (urlParameter) => urlParameter.name === 'collection',
    )
    const urlParameterValue = urlParameter?.value as string | null | undefined

    return urlParameterValue ?? null
  }, [activeCollectionUrlParameters])

  const setQuickFilterValue = useCallback(
    (newValue: string | null) => {
      updateCollectionUrlParameters([
        {
          name: 'collection',
          value: newValue,
        },
      ])
    },
    [updateCollectionUrlParameters],
  )

  // Child collection metadata
  const childCollection = useMemo(() => {
    if (!quickFilterSelectedValue) {
      return
    }

    const collection = collections.find(
      (collection) => collection.slug === quickFilterSelectedValue,
    )

    return collection
  }, [collections, quickFilterSelectedValue])

  useMetadataOverride(childCollection?.seo)

  return (
    <ProductCollectionQuickFilterContext.Provider
      value={{
        quickFilterEnabled: enabled,
        quickFilterOptions,
        quickFilterSelectedValue,
        setQuickFilterValue,
      }}
    >
      {children}
    </ProductCollectionQuickFilterContext.Provider>
  )
}
