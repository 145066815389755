import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react'

import {
  type SanityFilter,
  type SanityFilterGroup,
  type SanitySort,
  type SanitySortOptionType,
} from '@data/sanity/queries/types/cart'
import { type SanityProductFragment } from '@data/sanity/queries/types/product'
import { type UrlParameter, usePrevious, useUrlParameters } from './hooks'
import {
  getUpdatedParameterMapper,
  mergeFilterSlugs,
} from './product-collection'
import { SiteContext } from './site-context'

interface ProductCollectionContextProps {
  filterGroups: SanityFilterGroup[]
  defaultSortOption: SanitySortOptionType | null
  activeCollectionUrlParameters: UrlParameter[]
  updateCollectionUrlParameters: (newParameters: UrlParameter[]) => void
}

interface ProductCollectionContextProviderProps {
  products: SanityProductFragment[]
  filter?: SanityFilter
  sort?: SanitySort
  children: ReactNode
}

export const ProductCollectionContext =
  createContext<ProductCollectionContextProps>({
    filterGroups: [],
    defaultSortOption: null,
    activeCollectionUrlParameters: [],
    updateCollectionUrlParameters: () => {},
  })

export const ProductCollectionContextProvider = ({
  products,
  filter,
  sort,
  children,
}: ProductCollectionContextProviderProps) => {
  const { isRouteChanging } = useContext(SiteContext)

  const filterGroups = useMemo<SanityFilterGroup[]>(
    () =>
      filter?.groups
        ?.map((group) => ({
          ...group,
          options: group.options.filter((option) =>
            mergeFilterSlugs(products).includes(option.slug.current),
          ),
        }))
        .filter((group) => group.options.length > 0) ?? [],
    [filter?.groups, products],
  )
  const defaultSortOption = useMemo(
    () => sort?.options?.[0]?.type ?? null,
    [sort],
  )

  // Manage URL parameters
  const [collectionUrlParameters, setCollectionUrlParameters] =
    useUrlParameters(
      [
        {
          name: 'page',
        },
        {
          name: 'sort',
          value: defaultSortOption,
        },
        {
          name: 'collection',
        },
        ...filterGroups.map((filterGroup) => ({
          name: filterGroup.slug.current,
        })),
      ],
      {
        hideDefaultValue: true,
      },
    )

  // Manage URL parameters
  const previousParameters = usePrevious(collectionUrlParameters)
  const activeCollectionUrlParameters = useMemo(
    () =>
      isRouteChanging && previousParameters
        ? previousParameters
        : collectionUrlParameters,
    [collectionUrlParameters, isRouteChanging, previousParameters],
  )

  // Handle filter and sort updates
  const updateCollectionUrlParameters = useCallback(
    (newParameters: UrlParameter[]) => {
      const updatedParameter = getUpdatedParameterMapper(newParameters)
      const newCollectionUrlParameters =
        activeCollectionUrlParameters.map(updatedParameter)
      setCollectionUrlParameters(newCollectionUrlParameters)
    },
    [activeCollectionUrlParameters, setCollectionUrlParameters],
  )

  return (
    <ProductCollectionContext.Provider
      value={{
        filterGroups,
        defaultSortOption,
        activeCollectionUrlParameters,
        updateCollectionUrlParameters,
      }}
    >
      {children}
    </ProductCollectionContext.Provider>
  )
}
